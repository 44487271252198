import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

function LoadingButton(props) {

    const { loading } = props;

    return (
        <Button {...props} endIcon={loading == true && <CircularProgress size={30} />} loading={undefined} disabled={props.disabled || loading}>
            {props.children}
        </Button>
    )


}

export default LoadingButton